import React, { useState } from "react"
import scrollToElement from "scroll-to-element"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalOffices from "../../components/globalOffices"
import ImageTween from "../../components/imageTween"
import OfficesFloorSchema from "../../components/officesFloorSchema"
import StoriesViewSvg from "../../components/storiesViewSvg"
import PartnerModal from "../../components/PartnerModal"

const Offices = () => {
  const lang = "pl"
  const [tab, setTab] = useState("")

  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false)

  return (
    <Layout lang={lang} translation="/offices/">
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SEO title="Biura" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakowski Kompleks biznesowy</h2>
          </div>
        </div>
      </section>

      <section className="offices-top global__padd--bigger">
        <span className="global__line"></span>
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1>Biura</h1>
          </div>
          <div className="col-md-6">
            <p>
              UNITY CENTRE to w pełni wyposażone zgodnie z oczekiwaniami naszych
              najemców, otwarte przestrzenie biurowe w centrum miasta,
              charakteryzujące się niesamowitym widokiem na panoramę miasta.
              Dowiedz się więcej o możliwościach wynajmu.
            </p>
          </div>
        </div>
        <div className="offices-top__nav">
          <div className="row">
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("1")
                  scrollToElement("#view")
                }}
                className={tab === "1" ? "current" : ""}
              >
                Piętra górne <span>UNITY Tower</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("11")
                  scrollToElement("#view")
                }}
                className={tab === "11" ? "current" : ""}
              >
                Piętra dolne <span>UNITY Tower</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("2")
                  scrollToElement("#view")
                }}
                className={tab === "2" ? "current" : ""}
              >
                UNITY <span>Square One</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("3")
                  scrollToElement("#view")
                }}
                className={tab === "3" ? "current" : ""}
              >
                UNITY <span>Square Two</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section
        className="offices-view offices-top global__padd--bigger"
        id="view"
      >
        <div className="row align-items-center">
          <div className="col-xl-6">
            <h1>Piętra</h1>
            <br />
            <p>
              Jeżeli potrzebujesz więcej szczegółów, wybierz konkretne piętro,
              które Cię interesuje:
            </p>
          </div>
        </div>
      </section>

      <section className="offices-view" id="view">
        <div className="offices-view__wrapper global__padd--bigger">
          <div className="img__wrapper">
            <img
              src={require("../../assets/images/store-view-new2.jpg")}
              alt=""
            />
            <StoriesViewSvg lang={lang} />

            <button
              className={`iconposition1pl offices-view__wrapper-ico${
                tab === "1" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("1")
              }}
            >
              <span>
                UNITY Tower <br /> Piętra górne
                <p>
                  26 piętra + parter <br />
                  17 500 m2 GLA - powierzchnia budynku <br />
                  586* - 621 m2 GLA - średnia powierzchnia piętra <br />
                  1 500 m2** GLA - powierzchnia usługowa <br />
                  10 wind <br />
                  2 klatki schodowe <br />
                  3,2m (2,7m) - wysokość kondygnacji
                </p>
                <p>
                  * piętra 22-24
                  <br />
                  ** parter i 1-sze piętro
                </p>
              </span>
            </button>
            <button
              className={`iconposition2pl offices-view__wrapper-ico${
                tab === "3" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("3")
              }}
            >
              <span>
                UNITY Square Two{" "}
                <p>
                  8 pięter + parter <br />
                  7 300 m2 GLA - powierzchnia budynku <br />
                  746 m2 GLA - średnia powierzchnia piętra <br />
                  2 080 m2***** GLA - powierzchnia usługowa <br />
                  4 windy <br />
                  2 klatki schodowe <br />
                  3,27m (2,77m) - wysokość kondygnacji
                </p>
                <p>***** parter i 1-sze piętro</p>
              </span>
            </button>
            <button className={`offices-view__wrapper-ico iconposition3pl`}>
              <span className="custom-narrow">
                RADISSON RED - OTWARCIE LATEM 2024
              </span>
            </button>
            <button
              className={`offices-view__wrapper-ico iconposition4pl${
                tab === "11" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("11")
              }}
            >
              <span>
                UNITY Tower <br /> Piętra dolne
                <p>
                  26 piętra + parter <br />
                  17 500 m2 GLA - powierzchnia budynku <br />
                  817*** - 1 082**** m2 GLA - średnia powierzchnia piętra <br />
                  1 500 m2 GLA - powierzchnia usługowa <br />
                  10 wind <br />
                  2 klatki schodowe <br />
                  3,2m (2,7m) - wysokość kondygnacji
                </p>
                <p>
                  ** parter i 1-sze piętro
                  <br />
                  *** 2-gie piętro
                  <br />
                  **** piętra 3-7
                </p>
              </span>
            </button>
            <button
              className={`offices-view__wrapper-ico iconposition5pl${
                tab === "2" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("2")
              }}
            >
              <span>
                UNITY Square One{" "}
                <p>
                  5 pięter + parter <br />
                  9 980 m2 GLA - powierzchnia budynku <br />
                  1 869 m2 GLA - średnia powierzchnia piętra <br />
                  1 582 m2 GLA - powierzchnia usługowa <br />
                  3 windy <br />
                  2 klatki schodowe <br />
                  3,2m (2,7m) - wysokość kondygnacji
                </p>
              </span>
            </button>
            <button className={`offices-view__wrapper-ico iconposition6pl`}>
              <span className="custom-narrow">
                RADISSON RED APARTMENTS - OTWARCIE LATEM 2024
              </span>
            </button>
          </div>
        </div>
      </section>

      <section className="offices-floor-schema global__padd--bigger">
        <span className="global__line"></span>
        <OfficesFloorSchema lang={lang} tab={tab} />
      </section>

      <section className="offices-content global__padd--bigger">
        {tab === "1" || tab === "11" ? (
          <div className="row no-gutters">
            <div className="col-md-5">
              <ImageTween
                src={require("../../assets/images/offices-content-image-tower.jpg")}
                srcSet={require("../../assets/images/offices-content-image-tower@2x.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="offices-content__inner sticky-wrapper">
                <h3>
                  Prestiżowe powierzchnie biurowe zlokalizowane w samym centrum
                  miasta.
                </h3>
                <p>
                  26-kondygnacyjny wieżowiec biurowy klasy „AA” o powierzchni 17
                  500 m². Jego przestrzeń z pewnością będzie odpowiadać
                  wymagającym standardom nowoczesnych firm, które chcą zapewnić
                  swoim pracownikom i partnerom najwyższą jakość środowiska
                  pracy. Dzięki projektowaniu zorientowanemu na człowieka,
                  elastycznemu podejściu do tworzenia biur i ergonomicznemu
                  wyposażeniu nasze wnętrza spełnią Twoje oczekiwania.
                </p>
              </div>
            </div>
          </div>
        ) : tab === "2" ? (
          <div className="row no-gutters">
            <div className="col-md-5">
              <ImageTween
                src={require("../../assets/images/offices-content-image-square-one.jpg")}
                srcSet={require("../../assets/images/offices-content-image-square-one@2x.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="offices-content__inner sticky-wrapper">
                <h3>
                  Prestiżowe powierzchnie biurowe zlokalizowane w samym centrum
                  miasta.
                </h3>
                <p>
                  5-kondygnacyjny (+parter) biurowiec klasy „AA” o powierzchni
                  10 700 m² został zaprojektowany z myślą o maksymalizacji
                  elastyczności środowiska pracy. To tu zaczyna się przyszłość
                  Twojego biznesu. Tu narodzą się nowatorskie koncepcje i
                  interdyscyplinarne pomysły z długofalową perspektywą.
                </p>
              </div>
            </div>
          </div>
        ) : tab === "3" ? (
          <div className="row no-gutters">
            <div className="col-md-5">
              <ImageTween
                src={require("../../assets/images/offices-content-image-square-two.jpg")}
                srcSet={require("../../assets/images/offices-content-image-square-two@2x.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="offices-content__inner sticky-wrapper">
                <h3>
                  Prestiżowe powierzchnie biurowe zlokalizowane w samym centrum
                  miasta.
                </h3>
                <p>
                  9-kondygnacyjny biurowiec klasy „AA” o powierzchni 7 200 m².
                  To najwyższy standard biur, zaprojektowanych z myślą o
                  nowoczesnych biznesach, które oczekują elastyczności
                  środowiska pracy, z najlepszym adresem w mieście i widokiem na
                  Stare Miasto. Nasze przestrzenie sprzyjają współpracy, dobremu
                  samopoczuciu i efektywności.
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>

      <GlobalOffices lang={lang} color="blue" />

      <section className="offices-icons global__padd--bigger">
        <div className="row">
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-1.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Biura z najpiękniejszym widokiem na Kraków</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-2.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Panoramiczny taras 100m nad ziemią</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-3.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Elastyczna przestrzeń biurowa</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-4.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Luksusowe apartamenty</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-5.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Centrum konferencyjno-szkoleniowe</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-6.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Ochrona 24h</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-7.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Dwupoziomowy parkingi podziemny</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-8.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Miejsca do ładowania samochodów elektrycznych</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-9.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Innowacyjny system parkowania</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-10.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Bulwar miejski dostępny dla wszystkich</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-11.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>200 stojaków rowerowych / rowerów miejskich</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-12.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Nowoczesne szatnie i prysznice</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-13.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Bogata infrastruktura handlowa</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-14.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Łatwy dostęp do usług bankowych</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-15.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Certyfikowany zielony budynek</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-16.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Otwierane okna</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-19.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Panele słoneczne</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-20.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Odnawialne źródła energii </h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-17.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>
                  Różnorodność restauracji i kawiarni zaledwie kilka kroków od
                  UNITY CENTRE, opera, ogród botaniczny
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../../assets/images/offices-content-ico-18.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>
                  Zaprojektowany z ideą „Design for all” z myślą o potrzebach
                  osób niepełnosprawnych.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="offices-community">
        <div className="global__padd--bigger">
          <h1>Społeczność, Zainspiruj się</h1>
          <div className="offices-community__wrapper">
            <div className="row align-items-center">
              <div className="col-lg-3 col-sm-6 text-center text-lg-left">
                <button
                  className="offices-community__toggle"
                  onClick={() => setIsPartnerModalOpen(true)}
                >
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/offices-logo-miele.svg")}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-lg-3 col-sm-5 text-center text-lg-left">
                <a
                  className="offices-community__link"
                  href="https://www.autodesk.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/autodesk-logo.png")}
                    alt="Autodesk"
                  />
                </a>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <p>
                  UNITY CENTRE skupia dynamiczną społeczność otwartą na
                  różnorodność i innowacje. Firmy, które już znalazły swoją
                  przestrzeń u nas doceniają elastyczność, nowoczesność i nacisk
                  postawiony na rozwój oraz przyjazną atmosferę pracy. Poznaj
                  marki, które nazywają UNITY CENTRE swoim biurem.
                </p>
              </div>
            </div>
          </div>
          <div className="offices-community__images">
            <div className="row align-items-center">
              <div className="col-sm-4">
                <ImageTween
                  src={require("../../assets/images/offices-content-image-5@2x.jpg")}
                  alt=""
                />
              </div>
              <div className="col-sm-3">
                <ImageTween
                  src={require("../../assets/images/offices-content-image-4@2x.jpg")}
                  alt=""
                />
              </div>
              <div className="col-sm-5">
                <ImageTween
                  src={require("../../assets/images/offices-content-image-6@2x.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {isPartnerModalOpen && (
        <PartnerModal
          image={require("../../assets/images/miele-map.jpg")}
          title="Miele Experience Center Kraków"
          descriptions={[
            {
              title: "Adres",
              content:
                "<p>Unity Square One<br />ul. Lubomirskiego 24<br />31-509 Kraków</p>",
            },
            {
              title: "Godziny otwarcia",
              content:
                "<p>Poniedziałki i środy 9:00 - 18:00<br />Wtorki i czwartki 10:00 - 18:00<br />Piątki 10:00 - 16:00<br />Soboty 10:00 - 15:00</p>",
            },
            {
              title: "Telefon kontaktowy",
              content: '<p><a href="tel:+48881090452">+48 881 090 452</a></p>',
            },
          ]}
          onClose={() => setIsPartnerModalOpen(false)}
        />
      )}
    </Layout>
  )
}

export default Offices
